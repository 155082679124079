.container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.img {
  height: 3rem;
  width: 3rem;
  /* border-radius: 1.5rem; */
  /* background: #886aff; */
	object-fit: contain;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  row-gap: 1rem;
  width: 100%;
	max-width: 48rem;
}
