.children {
  grid-area: children;
}

.description {
  grid-area: description;
  text-align: center;
}

.image {
  grid-area: image;
  height: 12rem;
  width: 12rem;
  justify-self: center;
	object-fit: contain;
}

.main {
  display: grid;
  grid-template-areas: "title title" "description description" "image bullets" "children children";
  grid-template-columns: 12rem 100fr;
  gap: 1rem;
  align-items: center;

  padding: 2rem;
  max-width: 48rem;
}

.title {
  grid-area: title;
  text-align: center;
}

@media (max-width: 41rem) {
  .main {
    grid-template-areas: "title" "description" "image" "bullets" "children";
    grid-template-columns: 100fr;
  }
}
