.main {
  display: flex;
  flex-flow: column;
  color: white;
  list-style-type: disc;
  width: fit-content;
  width: 100%;
  gap: 1rem;
  padding-left: 2rem;
}
