.main {
  max-width: 64rem;
  line-height: 2.4rem;
}

@media (max-width: 50rem) {
  .main {
    line-height: 2rem;
  }
}
