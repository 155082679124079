.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.copyright {
  color: white;
	opacity: 0.32;
}

.crystals {
  width: 100%;
  /* max-height: 3rem; */
}

.link {
  color: white;
  padding: 0.5rem 1rem;
	font-weight: 700;
}

.link:hover {
  text-decoration: underline;
}

.links {
	width: 100%;
  display: flex;
  flex-wrap: wrap;
	justify-content: center;
}

.logo {
  width: 100%;
  max-width: 64rem;
  object-fit: contain;
  opacity: 0.32;
}

.logo:hover {
	opacity: 0.64;
}

.main {
  background: #886aff;
  color: white;
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 2rem;

  padding: 2rem;
}
