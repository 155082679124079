.caret {
  height: auto;
  width: 1rem;
  margin-left: 0.5rem;
}

.container {
  padding: 1rem;
  font-weight: 700;
  color: #886aff;
  display: flex;
  gap: 0.5rem;
  position: relative;
}

.container:hover {
  cursor: pointer;
}

.container:hover div {
  display: flex;
}

.container:hover .caret {
  transform: rotate(-90deg);
}

.cta {
  background: #886aff;
  color: white;
  font-weight: 700;
}

.ctaSmall {
  display: none;
}

.hamburger {
  display: none;
}

.links {
  display: flex;
}

.links a {
  padding: 1rem;
  font-weight: 700;
}

.links a:hover {
  text-decoration: underline;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo:hover {
  background: #f0ecff;
}

.logo img {
  object-fit: contain;
  padding-left: 1rem;
  height: 3rem;
  width: 3rem;
}

.main {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;

  z-index: 10;
  background: white;
}

.services {
  position: absolute;
  top: 3.2rem;
  right: 0;
  display: flex;
  flex-flow: column;
  background: #f0ecff;
  border-top: none;
  z-index: 1;
}

@media (max-width: 28rem) {
  .logo span {
    display: none;
  }

  .logo img {
    padding-left: 0;
  }
}

@media (max-width: 60rem) {
  .ctaSmall {
    display: initial;
    padding: 1rem;
  }

  .hamburger {
    height: 3rem;
    width: 3rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 0;
  }

  .hamburger:hover {
    background: #f0ecff;
  }

  .hamburger div {
    height: 3px;
    width: 1.6rem;
    background: #886aff;
  }

  .hamburgerOpen {
    width: 3rem;
    position: relative;
    background: peachpuff;
  }

  .hamburgerOpen div {
    position: absolute;
  }

  .hamburgerOpen div:nth-of-type(1) {
    transform: rotate(45deg);
  }

  .hamburgerOpen div:nth-of-type(2) {
    display: none;
  }

  .hamburgerOpen div:nth-of-type(3) {
    transform: rotate(-45deg);
  }

  .links {
    display: none;
  }

  .small_nav {
    position: fixed;
    top: 3.2rem;
    background: white;
    display: flex;
    flex-flow: column;
		background: #f0ecff;
		font-weight: 700;
  }

  .small_nav a {
    padding: 1rem;
  }

  .small_nav a:hover {
    text-decoration: underline;
  }
}
