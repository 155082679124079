.check {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  border: 2px solid #886aff;
}

.checked {
  background-color: #886aff;
  background-image: url("./check.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
}

.item {
  padding: 1rem 0;

  display: grid;
  grid-template-areas: "check label";
  grid-template-columns: 2rem 100fr;
  align-items: center;
  gap: 1rem;

  background: none;
  color: #886aff;
  font-weight: normal;
  text-align: left;
}

.item:hover {
  cursor: pointer;
  background: #f0ecff;
}

.item label:hover {
  cursor: pointer;
}

.list {
  gap: 0;
}

.main {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.title {
  margin-left: 1rem;
	margin-bottom: 0.5rem;
}
