.icon {
  height: 6rem;
  width: 6rem;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  column-gap: 2rem;
	row-gap: 1rem;
  max-width: 12rem;
  width: 100%;
}

.title {
  font-size: 1.2rem;
}

@media (max-width: 29rem) {
  .main {
    flex-flow: row;
    text-align: left;
    max-width: none;
  }

  .icon {
    height: 4rem;
    width: 4rem;
  }
}
