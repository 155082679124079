.main {
  width: 100%;
  max-width: 36rem;

  display: flex;
  flex-flow: column;

  gap: 1rem;
}

.bullet {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bullet img {
  height: 1rem;
  width: 1rem;
}

.check {
  height: 1rem;
  width: 1rem;
  object-fit: contain;
}

h3 {
  grid-area: title;
}

.icon {
  width: 3rem;
  aspect-ratio: 1;
  border: none;
  grid-area: icon;
  object-fit: contain;
}

p {
  grid-area: description;
}

ul {
  grid-area: bullets;
  width: 100%;
}

@media (max-width: 50.5rem) {
  .icon {
    max-width: 6rem;
  }
  .main {
    grid-template-areas: "icon" "title" "bullets";
    grid-template-columns: auto;
    /* justify-items: center; */
  }
}
