.main {
  display: grid;
  grid-template-areas: "image description";
  grid-template-columns: 1.5rem auto;
  text-align: left;
  gap: 1rem;
  width: 100%;
}

.check {
  grid-area: image;
  height: 1.5rem;
  width: 1.5rem;
  object-fit: contain;
}

.description {
  grid-area: description;
  text-align: left;
	line-height: 2.4rem;
}

@media (max-width: 50rem) {
  .description {
    line-height: 2rem;
  }
}
