.icon {
  grid-area: icon;
  height: 4rem;
  width: 4rem;
}

.left {
  grid-area: left;
  text-align: left;
}

.main {
  display: grid;
  grid-template-areas: "icon left right";
  grid-template-columns: 4rem 100fr 5rem;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}

.right {
  grid-area: right;
}
