.image {
  height: 8rem;
  width: 8rem;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  width: 16rem;
}

.title {
  font-weight: 700;
  color: #c3b4ff;
}
