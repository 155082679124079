.main {
  display: grid;
  grid-template-areas: "image title" "description description" "button button";
  grid-template-columns: 4rem 100fr;
  gap: 1rem;
  align-items: center;

  max-width: 30rem;
  padding: 2rem;
}

.main button {
  grid-area: button;
  background: white;
  color: #886aff;
}

.main button:hover {
  background: white;
  color: #a48dff;
}

.main h3 {
  grid-area: title;
}

.main img {
  grid-area: image;
  height: 4rem;
  width: 4rem;
  object-fit: contain;
}

.main p {
  grid-area: description;
}

@media (max-width: 50rem) {
  .main {
    grid-template-columns: 2rem 100fr;
  }

  .main img {
    height: 2rem;
    width: 2rem;
  }
}
