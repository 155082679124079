.main {
  max-width: 30rem;
  background: white;
  display: flex;
  justify-content: center;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-areas: "image" "title" "description" "info";
}

.description {
  grid-area: description;
}

.img {
  grid-area: image;
  width: 100%;
  aspect-ratio: 16/9;
}

.info {
  grid-area: info;
}

.title {
  grid-area: title;
}
