@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.3s;
  font-family: Montserrat;
}

.benefit_show {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

p,
span,
input,
label,
li,
i {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgba(22, 22, 22, 0.64);
}

p {
  line-height: 1.8rem;
}

a {
  color: #886aff;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  cursor: pointer;
}

/* START */

.flex {
  display: flex;
}

.white {
  background: white;
}

.background {
  background: #f0ecff;
}

.benefits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;

  width: 100%;
  max-width: 48rem;
}

.benefits-2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 64rem;
}

.button {
  width: 100%;
  background: #886aff;
  color: white;
  font-weight: 700;
  padding: 1rem 2rem;
}

button {
  width: 100%;

  background: #886aff;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;

  border: none;
  padding: 1rem 2rem;
}

button:hover {
  background: #a48dff;
  cursor: pointer;
}

form {
  display: flex;
  flex-flow: column;
  gap: 2rem;
  width: 100%;
  max-width: 64rem;
}

h1,
h2 {
  color: #886aff;
  max-width: 64rem;
}

h3,
h4 {
  color: #886aff;
  /* color: #b19eff; */
}

h1,
h2 {
  font-size: 3.6rem;
}

h2 {
  text-align: center;
}

h3,
h4 {
  font-size: 1.8rem;
}

.bbody {
  display: flex;
  flex-flow: column;
  padding: 2rem;
  gap: 2rem;
  width: 100%;
  max-width: 52rem;
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
}
.page {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  /* overflow: hidden; */
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}

.bintro {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
  padding-top: 12rem;
  background: #f0ecff;
}

.bintro h1 {
  max-width: 48rem;
}

.section {
  display: flex;
  flex-flow: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* max-width: 64rem; */
  position: relative;
  /* overflow: hidden; */

  padding: 4rem 2rem;
}

.check {
  height: 1.6rem;
  width: 1.6rem;
  background-size: contain;
}

.comparison {
  background: white;
  display: flex;
  flex-flow: column;
  border: 2px solid #886aff;
  color: #886aff;
  position: relative;
  width: 100%;
  max-width: 48rem;
}

.cash {
  position: sticky;
  top: 3rem;
  width: 100%;
  z-index: 4;
  margin: 0 1rem;
}

.cheader {
  background: #886aff;
  font-weight: bold;
}

.crow {
  display: flex;
}

.comcol1 {
  min-height: 3rem;
  width: 100%;
  min-width: 4rem;
  text-align: left;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.comcol2 {
  min-height: 3rem;
  width: 4rem;
  line-height: 3rem;
  border-left: 1px solid #b19eff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 50rem) {
  .comcol2 {
    width: 12rem;
  }
  .remium:after {
    content: "remium";
  }
  .ssentials:after {
    content: "ssentials";
  }
}

.disclaimer {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 40rem;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
}

.food {
  position: absolute;
  height: 12rem;
  width: 12rem;
  z-index: -1;
  opacity: 0.24;
  filter: blur(0.25rem);
}

.fly {
  margin-bottom: 100vh;
  transition: all 4s;
}

.form_contact {
  width: 100%;
  max-width: 40rem;
}

.intro {
  display: grid;
  grid-template-areas: "h1 image" "p image" "button image";
  grid-template-columns: 50fr 50fr;
  justify-items: center;

  max-width: 74rem;
  padding: 4rem;
  gap: 2rem;
  margin: 6rem auto;

  position: relative;
}

.intro h1 {
  grid-area: h1;
  max-width: 32rem;
}

.intro p {
  grid-area: p;
  max-width: 32rem;
}

.link {
  background: #886aff;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  width: 100%;
  grid-area: button;
  max-width: 32rem;
}

.link:hover {
  text-decoration: underline;
}

.intro img {
  grid-area: image;
}

.intro_image {
  width: 60%;
  max-width: 20rem;
  height: auto;
  aspect-ratio: initial;
  background-size: contain;

  position: absolute;
}

.intro_image_hidden {
  animation: slide_out 1s ease-in forwards;
}

.intro_image_shown {
  animation: slide_in 1s ease-out forwards;
}

.intro_left {
  display: flex;
  flex-flow: column;

  gap: 1rem;
  z-index: 2;
}

.intro_right {
  position: relative;
  width: 100%;
  height: 24rem;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
}

.logo_info {
  height: 3rem;
  aspect-ratio: 1;
  object-fit: contain;
}

.pricing {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 48rem;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.services {
  width: 100%;
  max-width: 64rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.submitted {
  width: 100%;

  background: #b19eff;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;

  border: none;
  padding: 1rem 2rem;
}

ul {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  list-style-type: none;
  justify-content: center;
}

@keyframes slide_in {
  0% {
    margin-left: 150vw;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes slide_out {
  0% {
    margin-bottom: 0;
  }
  100% {
    margin-bottom: 200vh;
  }
}

@media (max-width: 50rem) {
  button {
    font-size: 1rem;
    padding: 1rem;
  }
  p,
  span,
  input,
  li,
  label,
  i {
    font-size: 1rem;
  }
  h1,
  h2 {
    font-size: 2.4rem;
  }
  h3,
  h4 {
    font-size: 1.2rem;
  }
  .bbody {
    padding: 1rem;
  }
  .food {
    display: none;
  }
  .intro_image {
    z-index: -1;
  }
  .section {
    padding: 2rem 1rem;
    gap: 2rem;
  }
}

@media (max-width: 74rem) {
  @keyframes slide_out {
    0% {
      margin-right: 0;
      opacity: 1;
    }
    100% {
      margin-right: 150vw;
      opacity: 0;
    }
  }

  .intro {
    grid-template-areas: "h1" "p" "image" "button";
    grid-template-columns: auto;
  }

  .intro a {
    margin-top: 8rem;
  }

  .intro p {
    margin-bottom: 6rem;
  }

  .intro_image {
    max-width: 12rem;
  }

  .link {
    font-size: 1rem;
  }

  .submitted {
    font-size: 1rem;
  }
}
