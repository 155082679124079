.main {
  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: 50% 50%;
  gap: 2rem;

  max-width: 64rem;
}

@media (max-width: 50rem) {
  .main {
    grid-template-areas: "left" "right";
		grid-template-columns: 100%;
  }
}
