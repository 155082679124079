.error {
  color: red;
}

.label {
  margin-left: 1rem;
}

.input {
  border: none;
  background: #f0ecff;
  padding: 1rem;
  border-bottom: 2px solid #886aff;
  width: 100%;
	font-weight: 700;
	color: #886aff;
}

.main {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}
